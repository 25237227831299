import { omit, merge, findIndex } from 'lodash';

function wizardSteps(steps) {
  const firstIncompleteIndex = findIndex(steps, step => !step.complete);
  if (firstIncompleteIndex === -1) return steps;

  return steps.map((step, idx) => (
    idx === firstIncompleteIndex
    ? { ...step, active: true }
    : step
  ));
}

const fulfilled = (state, { payload }) => (
  merge(
    omit(state, 'organization.setupWizard'),
    {
      organization: {
        setupWizard: {
          ...payload,
          steps: wizardSteps(payload.steps),
        }
      }
    }
  )
);

const defaultExport = { fulfilled };

export default defaultExport;
