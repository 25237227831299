import React from 'react';
import classNames from 'classnames';
import './index.css';

/**
 * Reusable component containing a button
 */
function ButtonLink(props) {
  const conditionalClassNames = { rounded: props.rounded, disabled: props.disabled, 'full-width': props.fullWidth };

  return(
    <div className={classNames('button', conditionalClassNames)}>
      <a
        className='button__input'
        disabled={props.disabled}
        href={props.href}
      >
        {props.text}
      </a>
    </div>
  );
}

ButtonLink.defaultProps = {
  rounded: false,
  disabled: false
};

export default ButtonLink;
