import React from 'react';
import queryString from 'query-string';
import Spinner from 'components/Spinner';
import {
  LOGIN_ROUTE,
  LOGIN_CONFIRMATION_ROUTE,
} from 'utils/routes';
import { handleAdpLogin } from 'utils/api';

/**
 * Component that handles ADP OAuth redirect for login initiated from the app.
 */
class AdpSSOLogin extends React.Component {
  componentDidMount() {
    const {
      history,
      location,
      actions: { addGlobalAlert },
    } = this.props;

    const { code, state } = queryString.parse(location.search);

    handleAdpLogin(code, state)
      .then(({ isAdmin, isEnrolledInAbsorb }) => {
        history.push({ pathname: LOGIN_CONFIRMATION_ROUTE, state: { isAdmin, isEnrolledInAbsorb } })
      })
      .catch((e) => {
        history.push(LOGIN_ROUTE);
        switch(e.status) {
          case 404:
            addGlobalAlert({
              message: 'User not found. Ensure you have an active subscription on ADP Marketplace.'
            });
            break;
          case 401:
            addGlobalAlert({
              message: 'Login failed. Ensure you have an active subscription on ADP Marketplace.'
            });
            break;
          default:
            addGlobalAlert({
              message: 'Login failed. Please try again or contact your support representative.'
            });
        }
      });
  }

  render() {
    return(<Spinner />);
  }
}

export default AdpSSOLogin;
