import React from 'react';
import Alert from 'react-bootstrap/Alert';
import './index.css';

/**
 * Reusable component containing a global alert
 */
function GlobalAlert(props) {
  return(
    <div className='global-alert'>
      <Alert variant={props.variant} className='global-alert__message'>
        {props.text}
      </Alert>
    </div>
  );
}

GlobalAlert.defaultProps = {
  variant: 'primary'
}

export default GlobalAlert;
