import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';

class ConfirmSyncModal extends React.Component {
  render() {
    const { onConfirm, onHide } = this.props;
    return(
      <Modal
        show={true}
        onHide={onHide}
        centered
      >
        <h2>Are you sure you want to start a manual sync?</h2>
        <div className='confirm-sync-modal__body'>
          <p>This may take up to 15 minutes depending on the size of your organization.</p>
        </div>
        <div className='confirm-sync-modal__actions'>
          <Button
            rounded
            text='Cancel'
            onClick={onHide}
          />
          <Button
            style={{ width: '180px' }}
            rounded
            text='Confirm'
            onClick={onConfirm}
          />
        </div>
      </Modal>
    );
  }
}

export default ConfirmSyncModal;
