import React from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner';
import './index.css';

/**
 * Reusable component containing a spinner
 */
function Spinner(props) {
  return(
    <div className='spinner'>
      <BootstrapSpinner animation={props.animation} />
    </div>
  );
}

Spinner.defaultProps = {
  animation: 'border'
}

export default Spinner;
