import React from 'react';
import classnames from 'classnames';
import { ADP_MANAGE_APPS_URL } from 'utils/constants';
import './index.css';

class Account extends React.Component {
  get header() {
    return(
      <div>
        <h1 className='dashboard__header'>Account</h1>
      </div>
    );
  }

  get activeStatus() {
    const { organization: { account: { active } } } = this.props;
    const status = active ? 'active' : 'inactive';

    return(
      <div className='dashboard__settings-item'>
        <div className='dashboard__settings-item-content'>
          <div className='dashboard__account-subscription-status'>
            Your subscription is&nbsp;
            <em className={classnames({ active, inactive: !active })}>{ status }</em>.
          </div>
        </div>
      </div>
    );
  }

  get organizationName() {
    const { organization: { account: { name } } } = this.props;
    return(
      <div className='dashboard__settings-item'>
        <div className='dashboard__settings-item-content'>
          <div className='dashboard__settings-item-content-header'>
            Organization name
          </div>
        </div>
        <div className='dashboard__settings-item-value'>
          { name }
        </div>
      </div>
    );
  }

  get subscriptionCode() {
    const { account: { subscriptionCode } } = this.props.organization;
    if (!subscriptionCode) return null;

    return(
      <div className='dashboard__settings-item'>
        <div className='dashboard__settings-item-content'>
          <div className='dashboard__settings-item-content-header'>
            Subscription Code
          </div>
          <p>A unique subscription code for ADP marketplace.</p>
        </div>
        <div>
          <code className='dashboard__settings-subscription-code'>{ subscriptionCode }</code>
        </div>
      </div>
    );
  }

  get subscriptionUsers() {
    const {
      account: { subscriptionNumUsers, enrolledUsersCount },
    } = this.props.organization;

    return(
      <div className='dashboard__settings-item'>
        <div className='dashboard__settings-item-content'>
          <div className='dashboard__settings-item-content-header'>
            Subscription employees (used/purchased)
          </div>
          <p>You can manage your subscription on&nbsp;
            <a
              target='_blank'
              href={ADP_MANAGE_APPS_URL}
              rel='noopener noreferrer'
            >
              ADP Marketplace
            </a>
            .
          </p>
        </div>
        <div className='dashboard__settings-item-value'>
          { enrolledUsersCount } / { subscriptionNumUsers }
        </div>
      </div>
    );
  }

  render() {
    return(
      <div className='dashboard__content'>
        <div className='dashboard__settings'>
          { this.header }
          <div className='dashboard__account-items'>
            { this.activeStatus }
            { this.organizationName }
            { this.subscriptionCode }
            { this.subscriptionUsers }
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
