import {
  enrollUsers as enrollUsersMethod,
  getEnrollments as getEnrollmentsMethod,
  updateEnrollmentRule as updateEnrollmentRuleMethod,
} from 'utils/api';
import {
  ENROLL_USERS,
  GET_ENROLLMENTS,
  UPDATE_ENROLLMENT_RULE,
} from 'utils/actionTypes';
import { addGlobalAlert, removeGlobalAlert } from './app';

export const getEnrollments = () => (dispatch) => {
  return getEnrollmentsMethod().then(({ enrollments }) => {
    dispatch({
      type: `${GET_ENROLLMENTS}_FULFILLED`,
      enrollments,
    });
    return enrollments;
  });
}

export const enrollmentFulfilled = () => dispatch => {
  dispatch({ type: `${ENROLL_USERS}_FULFILLED` });
}

export const enrollmentRejected = () => dispatch => {
  dispatch({ type: `${ENROLL_USERS}_REJECTED` });
}

export const enrollUsers = (params) => (dispatch, getState) => {
  removeGlobalAlert()(dispatch);
  dispatch({ type: `${ENROLL_USERS}_PENDING` });
  return enrollUsersMethod(params).catch(res => {
    addGlobalAlert({ message: 'Unknown error. Please try again or contact support', variant: 'danger' })(dispatch);
    enrollmentRejected()(dispatch);
  });
}

export const updateEnrollmentRule = (enrollmentRule) => (dispatch) => {
  removeGlobalAlert()(dispatch);
  return updateEnrollmentRuleMethod(enrollmentRule).then(({ enrollmentRule }) => {
    dispatch({
      type: `${UPDATE_ENROLLMENT_RULE}_FULFILLED`,
      payload: enrollmentRule,
    });
  });
}
