import typeToReducer from 'type-to-reducer';
import { merge, omit } from 'lodash';
import {
  LOGOUT_USER,
  FETCH_USER,
  GET_ENROLLMENTS,
  ADD_GLOBAL_ALERT,
  REMOVE_GLOBAL_ALERT,
  GET_ORGANIZATION_ACCOUNT,
  GET_ORGANIZATION_SETTINGS,
  GET_ORGANIZATION_ACTIVITY,
  GET_ORGANIZATION_CONFIG,
  GET_SETUP_WIZARD,
  REFRESH_SETUP_WIZARD,
  SETUP_PUSHER,
  GET_ADP_CODE_LISTS,
  UPDATE_ENROLLMENT_RULE,
  ADP_SYNC,
  ABSORB_SYNC,
  GET_ADP_CONSENT,
  ENROLL_USERS,
} from 'utils/actionTypes';
import setupWizard from './setupWizard';
import getOrganizationActivity from './getOrganizationActivity';

const initialState = {
  user: null,
  organization: null,
  enrollments: null,
  loading: {
    initial: true,
    adpSync: false,
    enrollment: false,
    absorbSync: false,
    adpConsent: false,
    pusher: true,
  },
  globalAlert: null,
};

export default typeToReducer(
  {
    [LOGOUT_USER]: {
      FULFILLED: (state) => ({
        ...state,
        globalAlert: null,
      })
    },
    [FETCH_USER]: {
      FULFILLED: (state, { user }) => ({
        ...state,
        user,
        loading: {
          ...state.loading,
          initial: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        user: null,
        loading: {
          ...state.loading,
          initial: false,
        },
      })
    },
    [GET_ENROLLMENTS]: {
      FULFILLED: (state, { enrollments }) => ({
        ...state,
        enrollments,
      })
    },
    [ADD_GLOBAL_ALERT]: (state, { message, variant }) => ({
      ...state,
      globalAlert: {
        message,
        variant,
      }
    }),
    [REMOVE_GLOBAL_ALERT]: (state) => ({
      ...state,
      globalAlert: null,
    }),
    [GET_ORGANIZATION_ACCOUNT]: {
      FULFILLED: (state, { payload }) => (
        merge(
          omit(state, 'organization.account'),
          {
            organization: {
              account: payload,
            },
          }
        )
      )
    },
    [GET_ORGANIZATION_SETTINGS]: {
      FULFILLED: (state, { payload }) => (
        merge(
          omit(state, 'organization.settings'),
          {
            organization: {
              settings: payload,
            },
          }
        )
      )
    },
    [GET_ORGANIZATION_ACTIVITY]: {
      FULFILLED: getOrganizationActivity.fulfilled,
    },
    [GET_ORGANIZATION_CONFIG]: {
      FULFILLED: (state, { payload }) => (
        merge(
          omit(state, 'organization.config'),
          {
            organization: {
              config: payload,
            },
          }
        )
      )
    },
    [GET_SETUP_WIZARD]: {
      FULFILLED: setupWizard.fulfilled,
    },
    [REFRESH_SETUP_WIZARD]: {
      FULFILLED: setupWizard.fulfilled,
    },
    [GET_ADP_CODE_LISTS]: {
      FULFILLED: (state, { payload }) => (
        merge(
          omit(state, 'organization.settings.adpCodeLists'),
          {
            organization: {
              settings: {
                adpCodeLists: payload,
              }
            },
          }
        )
      )
    },
    [UPDATE_ENROLLMENT_RULE]: {
      FULFILLED: (state, { payload }) => (
        merge(
          omit(state, 'organization.settings.enrollmentRule'),
          {
            organization: {
              settings: {
                enrollmentRule: payload,
              }
            }
          }
        )
      )
    },
    [ADP_SYNC]: {
      PENDING: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpSync: true,
        },
      }),
      FULFILLED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpSync: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpSync: false,
        },
      }),
    },
    [ABSORB_SYNC]: {
      PENDING: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          absorbSync: true,
        },
      }),
      FULFILLED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          absorbSync: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          absorbSync: false,
        },
      }),
    },
    [ENROLL_USERS]: {
      PENDING: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          enrollment: true,
        },
      }),
      FULFILLED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          enrollment: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          enrollment: false,
        },
      }),
    },
    [GET_ADP_CONSENT]: {
      PENDING: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpConsent: true,
        },
      }),
      FULFILLED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpConsent: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          adpConsent: false,
        },
      }),
    },
    [SETUP_PUSHER]: {
      FULFILLED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          pusher: false,
        },
      }),
      REJECTED: (state) => ({
        ...state,
        loading: {
          ...state.loading,
          pusher: false,
        },
      }),
    },
  },
  initialState,
);
