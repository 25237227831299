import { datadogRum } from '@datadog/browser-rum';
const {
  REACT_APP_STAGE,
  REACT_APP_DATADOG_APP_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
} = process.env;

export const initDatadog = () => datadogRum.init({
  applicationId: REACT_APP_DATADOG_APP_ID,
  clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: `ryley-learning-frontend-${REACT_APP_STAGE}`,
  env:REACT_APP_STAGE,
  sampleRate: 100,
  trackInteractions: true
});
