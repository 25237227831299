import React from 'react';
import './index.css';
import { BsEnvelope } from 'react-icons/bs';
import classNames from 'classnames';
import Profile from './Profile';
import Logo from 'images/logo.png';

/**
 * Reusable component containing the top nav bar
 */
class TopBar extends React.Component {
  get logo() {
    return(
      <a href={'/'}>
        <img
          src={Logo}
          className='top-bar__logo'
          alt='Logo'
        />
      </a>
    );
  }

  get messages() {
    if (this.props.logoOnly) return null;

    return (
      <div className='top-bar__messages'>
        <a className='top-bar__messages-link' href='mailto:help@ryleylearning.com'>
          <BsEnvelope size='1.5em' />
        </a>
      </div>
    );
  }

  render() {
    return(
      <div className={classNames('top-bar', { 'logo-only': this.props.logoOnly })}>
        <div className='top-bar__column logo'>
          { this.logo }
        </div>
        <div className='top-bar__column messages'>
          { this.messages }
        </div>
        <Profile {...this.props} />
      </div>
    );
  }
}

export default TopBar;
