import React from 'react';
import Spinner from 'components/Spinner';
import { adpGetAuthorizeUrl } from 'utils/api';
import { adminUserIsNotAuthenticated } from 'utils/auth';

/**
 * Component that handles ADP OAuth redirect for login initiated from ADP "My Apps".
 */
class AdpSSOForwarder extends React.Component {
  componentDidMount() {
    return adpGetAuthorizeUrl()
      .then((url) => {
        window.location.href = url;
      })
  }

  render() {
    return(<Spinner />);
  }
}

export default adminUserIsNotAuthenticated(AdpSSOForwarder);
