import { get, post } from '../ajax';
import {
  ABSORB_ENROLLMENTS_PATH,
  ABSORB_ENROLL_USERS_PATH,
  ABSORB_SSO_PARAMETERS_PATH,
  ABSORB_GET_UNENROLLED_USERS_PATH,
  ABSORB_SYNC_PATH,
} from './paths';

export const getEnrollments = async () => {
  return get(ABSORB_ENROLLMENTS_PATH);
}

export const enrollUsers = async ({ userIds, isWizardStep }) => {
  return post(ABSORB_ENROLL_USERS_PATH, { userIds, isWizardStep });
}

export const absorbGetUnenrolledUsers = async () => {
  return get(ABSORB_GET_UNENROLLED_USERS_PATH).then(({ users }) => users);
}

export const absorbGetSSOParameters = async () => {
  return get(ABSORB_SSO_PARAMETERS_PATH);
}

export const absorbSync = async (params) => {
  return post(ABSORB_SYNC_PATH, params);
}
