import React from 'react';
import { groupBy, keyBy, mapValues, omit, isEmpty } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Radio from 'components/Radio';
import ButtonWithSpinner from 'components/ButtonWithSpinner';

class AutoEnrollModal extends React.Component {
  constructor(props) {
    super(props);
    const { enrollmentRule, adpCodeLists } = props;
    const groupedConditions = mapValues(
      groupBy(enrollmentRule.conditions.map(c => ({ label: c.attribute, value: c.value })), 'label'),
      conditions => keyBy(conditions, 'value'),
    );

    this.state = {
      formValues: {
        enabled: enrollmentRule.enabled,
        conditions: {
          department: groupedConditions.department || {},
          businessUnit: groupedConditions.businessUnit || {},
          costNumber: groupedConditions.costNumber || {},
          workLocation: groupedConditions.workLocation || {},
        }
      },
      selectOptions: {
        department: adpCodeLists.departments.map(this.selectOption),
        businessUnit: adpCodeLists.businessUnits.map(this.selectOption),
        costNumber: adpCodeLists.costNumbers.map(this.selectOption),
        workLocation: adpCodeLists.locations.map(this.selectOption),
      },
      showConditions: enrollmentRule.conditions.length > 0,
    }
  }

  onSubmit = (e) => {
    const {
      updateEnrollmentRule,
      onSuccess,
      enrollmentRule,
    } = this.props;
    e.preventDefault();
    const { formValues, showConditions } = this.state;

    const conditions = Object.entries(formValues.conditions).filter(entry => entry[1])
      .map(([attribute, options]) => Object.values(options).map(option => ({ attribute, value: option.value })))
      .flat();
    const payload = {
      id: enrollmentRule.id,
      enabled: formValues.enabled,
      conditions: showConditions ? conditions : [],
    }
    return updateEnrollmentRule(payload).then(onSuccess);
  }

  onSelectChange = (change, attribute) => {
    switch(change.action) {
      case 'select-option':
        return this.addItem(attribute, change.option);
      case 'remove-value':
      case 'pop-value':
        return this.removeItem(attribute, change.removedValue.value);
      case 'clear':
        return this.clearInput(attribute);
      default:
        return;
    }
  }

  clearInput = (attribute) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        conditions: {
          ...this.state.formValues.conditions,
          [attribute]: {},
        }
      }
    });
  }

  removeItem = (attribute, value) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        conditions: {
          ...this.state.formValues.conditions,
          [attribute]: {
            ...omit(this.state.formValues.conditions[attribute], value),
          },
        }
      }
    });
  }

  addItem = (attribute, option) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        conditions: {
          ...this.state.formValues.conditions,
          [attribute]: {
            ...this.state.formValues.conditions[attribute],
            [option.value]: option,
          },
        }
      }
    });
  }

  addCondition(option, attribute) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        conditions: {
          ...this.state.formValues.conditions,
          [attribute]: {
            ...this.state.formValues.conditions,
            [option.value]: option,
          }
        }
      }
    });
  }

  removeCondition(option, attribute) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        conditions: {
          ...this.state.formValues.conditions,
          [attribute]: {
            ...omit(this.state.formValues.conditions, option.value),
          }
        }
      }
    });
  }

  isSelected(item, attribute) {
    const { formValues: { conditions } } = this.state;
    return conditions[attribute] && conditions[attribute][item.value];
  }

  selectOption(item) {
    return {
      label: item.name ? `${item.codeValue} - ${item.name}` : item.codeValue,
      value: item.codeValue,
    }
  }

  codeListsSelect(attribute) {
    const { selectOptions } = this.state;
    const defaultOptions = selectOptions[attribute].filter(item => this.isSelected(item, attribute));

    return(
      <Select
        closeMenuOnSelect={false}
        isMulti
        defaultValue={defaultOptions}
        options={this.state.selectOptions[attribute]}
        onChange={(_, change) => this.onSelectChange(change, attribute)}
      />
    );
  }

  get conditions() {
    const { showConditions } = this.state;
    if (!showConditions) return null;

    return(
      <>
        <div style={{ marginTop: '30px' }}>
          Select automatic enrollment criteria. The rule will apply if at least one of the criteria matches.
        </div>
        <div className='auto-enrollments__condition-header'>Departments</div>
        { this.codeListsSelect('department') }
        <div className='auto-enrollments__condition-header'>Business Units</div>
        { this.codeListsSelect('businessUnit') }
        <div className='auto-enrollments__condition-header'>Cost Numbers</div>
        { this.codeListsSelect('costNumber') }
        <div className='auto-enrollments__condition-header'>Work Locations</div>
        { this.codeListsSelect('workLocation') }
      </>
    );
  }

  get submitDisabled() {
    const {
      formValues: { conditions },
      showConditions,
    } = this.state;
    if (!showConditions) return false;

    return Object.values(conditions).every(condition => isEmpty(condition));
  }

  render() {
    const { onHide } = this.props;
    const {
      formValues: { enabled },
      showConditions,
    } = this.state;
    return(
      <Modal
        show={true}
        onHide={onHide}
        size='lg'
      >
        <h2>Automatic enrollment rule</h2>
        <form className='auto-enrollments__form'>
          <Radio
            id='auto-enrollments-enabled-1'
            label='Enable based on all available criteria'
            checked={enabled && !showConditions}
            onChange={() => this.setState({ formValues: { ...this.state.formValues, enabled: true }, showConditions: false })}
          />
          <Radio
            id='auto-enrollments-enabled-2'
            label='Enable based on select criteria'
            checked={enabled && showConditions}
            onChange={() => this.setState({ formValues: { ...this.state.formValues, enabled: true }, showConditions: true })}
          />
          <Radio
            id='auto-enrollments-enabled-3'
            label='Disable'
            checked={!enabled && !showConditions}
            onChange={() => this.setState({ formValues: { ...this.state.formValues, enabled: false }, showConditions: false })}
          />
          { this.conditions }
          <div className='auto-enrollments__form-actions'>
            <ButtonWithSpinner
              type='submit'
              text='Save'
              disabled={this.submitDisabled}
              onClick={this.onSubmit}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default AutoEnrollModal;
