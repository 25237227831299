import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import Button from 'components/Button';
import ButtonWithSpinner from 'components/ButtonWithSpinner';
import LoadingIcon from 'components/LoadingIcon';
import ConfirmSyncModal from './ConfirmSyncModal';
import AutoEnrollModal from './AutoEnrollModal';
import './index.css';

class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirmSyncModal: false,
      showAutoEnrollModal: false,
    }
  }

  get adpSyncButton() {
    const {
      loading: { adpSync: adpSyncInProgress },
    } = this.props;
    if (adpSyncInProgress) {
      return (
        <div className='dashboard__settings-sync-button-wrapper'>
          <LoadingIcon />
        </div>
      );
    }
    return (
      <div className='dashboard__settings-sync-button-wrapper'>
        <Button
          text={ 'Start' }
          onClick={() => this.setState({ showConfirmSyncModal: true })}
        />
      </div>
    );
  }

  get header() {
    return(
      <div>
        <h1 className='dashboard__header'>Settings</h1>
      </div>
    );
  }

  get autoEnrollBadge() {
    const {
      organization: { settings: { enrollmentRule: { enabled } } },
    } = this.props;

    let badgeText;
    let variant;
    if (enabled && !this.autoEnrollmentPaused) {
      badgeText = 'On';
      variant = 'success';
    } else if (this.autoEnrollmentPaused) {
      badgeText = 'Paused';
      variant = 'warning';
    } else {
      badgeText = 'Off';
      variant = 'danger';
    }

    return(
      <Badge
        style={{ marginRight: '20px' }}
        variant={variant}
      >
        { badgeText }
      </Badge>
    );
  }

  get autoEnrollmentPaused() {
    const {
      organization: {
        settings: { enrollmentRule: { enabled } },
        account: { subscriptionNumUsers, enrolledUsersCount },
      },
    } = this.props;
    return enabled && subscriptionNumUsers === enrolledUsersCount;
  }

  get autoEnrollmentDetails() {
    if (!this.autoEnrollmentPaused) {
      return (
        <p>
          Enrollment rule applies to employees whose records change with respect to the selected criteria.
          If you select all available criteria, the rule will apply if any of the following properties change: department, business unit, work location or cost number.
        </p>
      );
    }

    return(
      <p className='dashboard__settings-auto-enrollment-warning'>
        <BsExclamationTriangleFill size='1.5em' color='#fac241'/>&nbsp;
        All available subscription employees have been used.
      </p>
    );
  }

  get autoEnrollButton() {
    const {
      actions: { adpGetCodeLists },
      organization: { settings: { enrollmentRule: { enabled } } },
    } = this.props;

    return(
      <div className='auto-enrollments__button-wrapper'>
        { this.autoEnrollBadge }
        <ButtonWithSpinner
          text={enabled ? 'Edit' : 'Enable'}
          onClick={() => adpGetCodeLists().then(() => this.setState({ showAutoEnrollModal: true }))}
        />
      </div>
    );
  }

  get confirmModalManualSync() {
    if (!this.state.showConfirmSyncModal) return null;
    const { actions: { adpSync } } = this.props;

    return(
      <ConfirmSyncModal
        onHide={() => this.setState({ showConfirmSyncModal: false })}
        onConfirm={() => { adpSync(); this.setState({ showConfirmSyncModal: false }) }}
      >
      </ConfirmSyncModal>
    );
  }

  get autoEnrollModal() {
    if (!this.state.showAutoEnrollModal) return null;
    const {
      organization: { settings: { enrollmentRule, adpCodeLists } },
      actions: { updateEnrollmentRule },
    } = this.props;

    return(
      <AutoEnrollModal
        updateEnrollmentRule={updateEnrollmentRule}
        onHide={() => this.setState({ showAutoEnrollModal: false })}
        onSuccess={() => this.setState({ showAutoEnrollModal: false })}
        enrollmentRule={enrollmentRule}
        adpCodeLists={adpCodeLists}
      >
      </AutoEnrollModal>
    );
  }

  get content() {
    return (
      <div className='dashboard__settings-items'>
        <div className='dashboard__settings-item'>
          <div className='dashboard__settings-item-content'>
            <div className='dashboard__settings-item-content-header'>
              Manual sync
            </div>
            <p>
              All changes in your ADP account are synced automatically. There may be a slight delay
              for changes to appear in the Ryley Learning dashboard. A manual sync will start immediately.
            </p>
          </div>
          { this.adpSyncButton }
        </div>
        <div className='dashboard__settings-item'>
          <div className='dashboard__settings-item-content'>
            <div className='dashboard__settings-item-content-header'>
              Automatic enrollment
            </div>
            { this.autoEnrollmentDetails }
          </div>
          { this.autoEnrollButton }
        </div>
      </div>
    );
  }

  render() {
    return(
      <div className='dashboard__content'>
        <div className='dashboard__settings'>
          { this.header }
          { this.content }
          { this.confirmModalManualSync }
          { this.autoEnrollModal }
        </div>
      </div>
    );
  }
}

export default Settings;
