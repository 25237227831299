import React from 'react';
import { Link } from 'react-router-dom';
import { adminUserIsNotAuthenticated } from 'utils/auth';
import TopBar from 'components/TopBar';
import Spinner from 'components/Spinner';
import BoxContainer from 'components/BoxContainer';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import { LOGIN_ROUTE, DASHBOARD_ROUTE, LOGOUT_ROUTE } from 'utils/routes';
import { absorbGetSSOParameters } from 'utils/api';
import './index.css';

/**
 * Component containing the Login Confirmation page
 */
class LoginConfirmation extends React.Component {
  constructor(props) {
    super(props);
    const { location: { state } } = props;
    this.state = { userState: state };
  }

  componentDidMount() {
    const {
      history,
      actions: { addGlobalAlert }
    } = this.props;

    if (!this.state.userState) {
      // If page is visited directly, then it won't have userState.
      history.push(LOGIN_ROUTE);
      addGlobalAlert({ message: 'Please login to continue.' });
    } else if (this.isAdminNotEnrolled) {
      // Redirect admin user to dashboard if they are not enrolled in Absorb.
      this.redirectToDashboard();
    }
  }

  redirectToDashboard = () => {
    const {
      history,
      actions: { fetchUser }
    } = this.props;

    fetchUser().then(() => history.push(DASHBOARD_ROUTE));
  }

  redirectToAbsorb = () => {
    absorbGetSSOParameters()
      .then(({ ssoPayload, ssoUrl }) => this.setState({ ssoPayload, ssoUrl }))
      .then(() => this.samlFormElement.submit());
  }

  get isAdminNotEnrolled() {
    const { userState: { isAdmin, isEnrolledInAbsorb } } = this.state;
    return isAdmin && !isEnrolledInAbsorb;
  }

  get isEnrolledNotAdmin() {
    const { userState: { isAdmin, isEnrolledInAbsorb } } = this.state;
    return !isAdmin && isEnrolledInAbsorb;
  }

  get canRender() {
    if (!this.state.userState) return false;
    if (this.isAdminNotEnrolled) return false;

    return true;
  }

  get backToLoginButton() {
    return(
      <ButtonLink text='Back to Login' href={LOGIN_ROUTE} />
    );
  }

  get logoutLink() {
    return(<Link className='login-confirmation__secondary-link' to={LOGOUT_ROUTE}>Logout</Link>);
  }

  get proceedAsAdminButton() {
    return(
      <Button text='Proceed as Admin' onClick={this.redirectToDashboard} />
    );
  }

  get proceedAsLearnerButton() {
    const text = this.isEnrolledNotAdmin ? 'Proceed to Absorb' : 'Proceed as Learner';
    return(
      <Button text={text} onClick={this.redirectToAbsorb}/>
    );
  }

  get samlForm() {
    return(
      <form
        ref={e => this.samlFormElement = e}
        className='login-confirmation__saml-form'
        action={this.state.ssoUrl}
        method='POST'
      >
        <input readOnly={true} name='samlresponse' value={btoa(this.state.ssoPayload) || ''}/>
      </form>
    );
  }

  get boxContents() {
    const { userState: { isAdmin, isEnrolledInAbsorb } } = this.state;

    let message;
    let buttons;
    if (isAdmin && isEnrolledInAbsorb) {
      message = 'Welcome to Ryley Learning! Select if you would like to proceed as an Admin or a Learner user.';
      buttons = <>{ this.proceedAsAdminButton } { this.proceedAsLearnerButton } { this.logoutLink }</>;
    } else if (isEnrolledInAbsorb) {
      message = 'Welcome to Ryley Learning! Click the button below to complete the login. You will be redirected to Absorb LMS.';
      buttons = <>{ this.proceedAsLearnerButton } { this.logoutLink }</>;
    } else {
      message = 'You are not currently enrolled in a Ryley Learning course. ' +
        'If you believe this is an error, contact your ADP Marketplace administrator.';
      buttons = <>{ this.backToLoginButton } { this.logoutLink }</>;
    }

    return (
      <>
        <div className='login-confirmation__header-container'>
          <h2 className='login-confirmation__header'>Authentication successful</h2>
        </div>
        <p className='login-confirmation__message'>{ message }</p>
        <div className='login-confirmation__buttons'>{ buttons }</div>
      </>
    );
  }

  render() {
    if (!this.canRender) return(<Spinner />);

    return(
      <div className='login-confirmation'>
        <TopBar logoOnly={true} actions={this.props.actions} />
        <div className='login-confirmation__content'>
          <BoxContainer>
            { this.boxContents }
          </BoxContainer>
        </div>
        { this.samlForm }
      </div>
    );
  }
}

export default adminUserIsNotAuthenticated(LoginConfirmation);
