import React from 'react';
import './index.css';

/**
 * Reusable component containing a radio and its label
 */
const Radio = (props) => {
  return(
    <div className='radio'>
      <input
        id={props.id}
        type='radio'
        className='radio__input'
        onChange={props.onChange}
        checked={props.checked}
      />
      <label htmlFor={props.id} className='radio__label'>{props.label}</label>
    </div>
  );
}

export default Radio;
