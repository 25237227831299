import React from 'react';
import './index.css';
import classnames from 'classnames';

/**
 * Reusable component containing a badge
 */
function Badge(props) {
  return(
    <div className={classnames('rl-badge', { [props.color]: [props.color] })}>
      <span className='circle'/>
      {props.text}
    </div>
  );
}

export default Badge;
