import React from 'react';

/**
 * Reusable component containing general-purpose text input
 */
function TextInput(props) {
  return(
    <div className='text-input'>
      <label>
        <div className='text-input__label'>
          { props.label } { props.required ? '*' : null }
        </div>
        <div>
          <input
            className='text-input__input'
            required={props.required}
            type={props.name === 'password' ? 'password' : 'text'}
            value={props.value}
            onChange={props.onChange}
            name={props.name}
          />
        </div>
      </label>
    </div>
  );
}

TextInput.defaultProps = {
  required: false
}

export default TextInput;
