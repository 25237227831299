import { absorbSync as absorbSyncMethod } from 'utils/api';
import { ABSORB_SYNC } from 'utils/actionTypes';
import { addGlobalAlert, removeGlobalAlert } from './app';

export const absorbSyncFulfilled = () => dispatch => {
  dispatch({ type: `${ABSORB_SYNC}_FULFILLED` });
}

export const absorbSyncRejected = () => dispatch => {
  dispatch({ type: `${ABSORB_SYNC}_REJECTED` });
}

export const absorbSync = (params) => dispatch => {
  removeGlobalAlert()(dispatch);
  dispatch({ type: `${ABSORB_SYNC}_PENDING` });
  return absorbSyncMethod(params).catch(res => {
    addGlobalAlert({ message: 'Unknown error. Please try again or contact support', variant: 'danger' })(dispatch);
    absorbSyncRejected()(dispatch);
  });
}
