import { get } from 'lodash';
import {
  initializePusher,
  EVENT_NAMES as PUSHER_EVENT_NAMES,
} from 'utils/pusher';
import {
  GET_ORGANIZATION_ACCOUNT,
  GET_ORGANIZATION_SETTINGS,
  GET_ORGANIZATION_ACTIVITY,
  GET_ORGANIZATION_CONFIG,
  GET_SETUP_WIZARD,
  REFRESH_SETUP_WIZARD,
  SETUP_PUSHER,
} from 'utils/actionTypes';
import {
  getOrganizationAccount as getOrganizationAccountMethod,
  getOrganizationSettings as getOrganizationSettingsMethod,
  getOrganizationActivity as getOrganizationActivityMethod,
  getOrganizationConfig as getOrganizationConfigMethod,
  getSetupWizard as getSetupWizardMethod,
  refreshSetupWizard as refreshSetupWizardMethod,
} from 'utils/api';
import { adpSyncFulfilled, adpSyncRejected } from './adp';
import { enrollmentFulfilled, enrollmentRejected, getEnrollments } from './enrollment';
import { absorbSyncFulfilled, absorbSyncRejected } from './absorb';
import { addGlobalAlert } from './app';

export const getOrganizationAccount = () => (dispatch) => {
  return getOrganizationAccountMethod().then(account => {
    dispatch({
      type: `${GET_ORGANIZATION_ACCOUNT}_FULFILLED`,
      payload: account,
    });
    return account;
  });
}

export const getOrganizationSettings = () => (dispatch) => {
  return getOrganizationSettingsMethod().then(settings => {
    dispatch({
      type: `${GET_ORGANIZATION_SETTINGS}_FULFILLED`,
      payload: settings,
    });
    return settings;
  });
}

export const getOrganizationActivity = () => (dispatch) => {
  return getOrganizationActivityMethod().then(activity => {
    dispatch({
      type: `${GET_ORGANIZATION_ACTIVITY}_FULFILLED`,
      payload: activity,
    });
    return activity;
  });
}

export const getOrganizationConfig = () => (dispatch) => {
  return getOrganizationConfigMethod().then(config => {
    dispatch({
      type: `${GET_ORGANIZATION_CONFIG}_FULFILLED`,
      payload: config,
    });
    return config;
  });
}

export const getSetupWizard = () => (dispatch) => {
  return getSetupWizardMethod().then(setupWizard => {
    dispatch({
      type: `${GET_SETUP_WIZARD}_FULFILLED`,
      payload: setupWizard,
    });
    return setupWizard;
  });
}

export const refreshSetupWizard = (params) => (dispatch) => {
  return refreshSetupWizardMethod(params).then(res => {
    const { success, wizard } = res;
    if (success) {
      dispatch({
        type: `${REFRESH_SETUP_WIZARD}_FULFILLED`,
        payload: wizard,
      });
    } else {
      addGlobalAlert({ variant: 'danger', message: 'Unknown error. Please try again or contact support' })(dispatch);
      return Promise.reject();
    }
    return res.wizard;
  });
}

export const setupPusher = () => (dispatch, getState) => {
  return new Promise(resolve => {
    const { pusherChannel } = get(getState(), 'app.organization.config');
    const channel = initializePusher().subscribe(pusherChannel);

    channel.bind('pusher:subscription_succeeded', () => {
      dispatch({ type: `${SETUP_PUSHER}_FULFILLED` });

      channel.bind(PUSHER_EVENT_NAMES.ADP_SYNC_FINISHED, async ({ success, isWizardStep }) => {
        await getOrganizationActivity()(dispatch);
        if (success) {
          if (isWizardStep) {
            await getSetupWizard()(dispatch);
          }
          addGlobalAlert({ variant: 'success', message: 'ADP sync completed!' })(dispatch);
          adpSyncFulfilled()(dispatch);
        } else {
          adpSyncRejected()(dispatch);
          addGlobalAlert({ variant: 'danger', message: 'ADP sync failed. Please try again or contact support' })(dispatch);
        }
      });

      channel.bind(PUSHER_EVENT_NAMES.ENROLLMENT_FINISHED, async ({ success, isWizardStep }) => {
        await Promise.all([getOrganizationAccount()(dispatch), getOrganizationActivity()(dispatch)]);
        if (success) {
          await getEnrollments()(dispatch);
          if (isWizardStep) {
            await getSetupWizard()(dispatch);
          }
          addGlobalAlert({ variant: 'success', message: 'Enrollment completed!' })(dispatch);
          enrollmentFulfilled()(dispatch);
        } else {
          enrollmentRejected()(dispatch);
          addGlobalAlert({ variant: 'danger', message: 'Enrollment failed. Please try again or contact support' })(dispatch);
        }
      });

      channel.bind(PUSHER_EVENT_NAMES.ABSORB_SYNC_FINISHED, async ({ success, isWizardStep }) => {
        await Promise.all([getOrganizationAccount()(dispatch), getOrganizationActivity()(dispatch)]);
        if (success) {
          await getEnrollments()(dispatch);
          if (isWizardStep) {
            await getSetupWizard()(dispatch);
          }
          addGlobalAlert({ variant: 'success', message: 'Absorb sync completed!' })(dispatch);
          absorbSyncFulfilled()(dispatch);
        } else {
          absorbSyncRejected()(dispatch);
          addGlobalAlert({ variant: 'danger', message: 'Absorb sync failed. Please try again or contact support' })(dispatch);
        }
      });

      channel.bind(PUSHER_EVENT_NAMES.CUSTOM, async ({ message, error }) => {
        addGlobalAlert({ variant: error ? 'danger' : 'success', message })(dispatch);
      });

      resolve();
    });

    channel.bind('pusher:subscription_error', () => {
      dispatch({ type: `${SETUP_PUSHER}_REJECTED` });
      addGlobalAlert({ message: 'Unknown error. Please try again or contact support', variant: 'danger' })(dispatch);
      resolve();
    });
  });
}
