import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { LOGIN_ROUTE, DASHBOARD_ROUTE } from 'utils/routes';
import Spinner from 'components/Spinner';

const locationHelper = locationHelperBuilder({});

export const adminUserIsAuthenticated = connectedRouterRedirect({
  redirectPath: LOGIN_ROUTE,
  authenticatedSelector: state => !!state.app.user,
  authenticatingSelector: state => state.app.loading.initial,
  AuthenticatingComponent: Spinner,
  wrapperDisplayName: 'UserIsAuthenticated'
})

export const adminUserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || DASHBOARD_ROUTE,
  allowRedirectBack: false,
  authenticatedSelector: state => !state.app.user && !state.app.loading.initial,
  authenticatingSelector: state => state.app.loading.initial,
  AuthenticatingComponent: Spinner,
  wrapperDisplayName: 'UserIsNotAuthenticated'
})
