// User
export const LOGIN_USER_WITH_ADP_PATH = '/users/login_with_adp';
export const FETCH_CURRENT_USER_PATH = '/users/me';
export const LOGOUT_USER_PATH = '/users/logout';

// Organization
export const ORGANIZATION_ACCOUNT_PATH = '/organization/account';
export const ORGANIZATION_SETTINGS_PATH = '/organization/settings';
export const ENROLLMENT_RULE_PATH = '/organization/enrollment_rule';
export const ORGANIZATION_ACTIVITY_PATH = '/organization/activity';
export const ORGANIZATION_CONFIG_PATH = '/organization/config';
export const SETUP_WIZARD_PATH = '/organization/setup_wizard';
export const REFRESH_WIZARD_PATH = '/organization/refresh_wizard';
export const PUSHER_AUTH_PATH = '/organization/pusher_auth';

// ADP
export const ADP_GET_AUTHORIZE_URL_PATH = '/adp/authorize_url';
export const ADP_SYNC_PATH = '/adp/sync';
export const ADP_VERIFY_SSO_PATH = '/adp/verify_sso_state';
export const ADP_CODE_LISTS_PATH = '/adp/code_lists';

// Enrollment and Absorb
export const ABSORB_ENROLLMENTS_PATH = '/absorb/enrollments';
export const ABSORB_ENROLL_USERS_PATH = '/absorb/enroll_users';
export const ABSORB_SSO_PARAMETERS_PATH = '/absorb/sso_parameters';
export const ABSORB_GET_UNENROLLED_USERS_PATH = '/absorb/unenrolled_users';
export const ABSORB_SYNC_PATH = '/absorb/sync';
