import React from 'react';
import Spinner from 'components/Spinner';
import { WIZARD_STEP_NAMES } from 'utils/constants';
import { SETUP_WIZARD_ROUTE } from 'utils/routes';
import { adminUserIsAuthenticated } from 'utils/auth';

class SetupWizardCallback extends React.Component {
  componentDidMount() {
    const {
      history,
      actions: { refreshSetupWizard },
    } = this.props;

    return refreshSetupWizard({ refreshStep: WIZARD_STEP_NAMES.CONSENT }).then(() => history.push(SETUP_WIZARD_ROUTE));
  }

  render() {
    return(<Spinner />);
  }
}

export default adminUserIsAuthenticated(SetupWizardCallback);
