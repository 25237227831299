import React from 'react';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import Checkbox from 'components/Checkbox';

class GroupItem extends React.Component {
  get userList() {
    const { collapsed, users, isUserChecked, toggleUserChecked } = this.props;
    if (collapsed) return null;

    return users.map((user, idx) => (
      <li className='dashboard__modal-list-item indented' key={idx}>
        <Checkbox
          id={`checkbox-${user.id}-${idx}`}
          label={`${user.firstName} ${user.lastName}`}
          disabled={!user.canBeEnrolled}
          checked={isUserChecked(user.id)}
          onChange={() => toggleUserChecked(user.id)}
        />
        <div>{user.email}</div>
      </li>
    ));
  }

  get collapseIcon() {
    const { collapsed, toggleCollapseSection, groupName, groupItemLabel } = this.props;

    if (collapsed) {
      return(
        <AiOutlinePlusCircle
          size='1.6em'
          color='#1392BE'
          onClick={() => toggleCollapseSection({ groupName, groupItemLabel })}
        />
      );
    }

    return (
      <AiOutlineMinusCircle
        size='1.6em'
        color='#1392BE'
        onClick={() => toggleCollapseSection({ groupName, groupItemLabel })}
      />
    );
  }

  render() {
    const {
      groupName,
      groupItemLabel,
      isUserChecked,
      users,
      toggleCheckedGroupItem,
    } = this.props;
    const allSelected = users.every(({ id, canBeEnrolled }) => canBeEnrolled ? isUserChecked(id) : true);
    const noneSelected = users.every(({ id }) => !isUserChecked(id));
    const noneCanBeEnrolled = users.every(({ canBeEnrolled }) => !canBeEnrolled);

    return(
      <div>
        <div className='dashboard__modal-list-group-header'>
          <Checkbox
            id={`checkbox-${groupItemLabel}-group`}
            label={`${groupItemLabel} (${users.filter(u => u.canBeEnrolled).length})`}
            halfChecked={!allSelected && !noneSelected}
            checked={allSelected && !noneCanBeEnrolled}
            disabled={noneCanBeEnrolled}
            style={{ display: 'inline-block' }}
            onChange={() => toggleCheckedGroupItem({
              selectAll: noneSelected, groupName, groupItemLabel }
            )}
          />
          { this.collapseIcon }
        </div>
        { this.userList }
      </div>
    );
  }
}

export default GroupItem;
