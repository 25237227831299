import { get } from 'lodash';
import { ORG_ACTIVITY_STATUSES } from 'utils/constants';

const isAdpSyncInProgress = ({ lastAdpSync }) => (
  get(lastAdpSync, 'properties.status') === ORG_ACTIVITY_STATUSES.IN_PROGRESS
);
const isEnrollmentInProgress = ({ lastAbsorbEnroll }) => (
  get(lastAbsorbEnroll, 'properties.status') === ORG_ACTIVITY_STATUSES.IN_PROGRESS
);
const isAbsorbSyncInProgress = ({ lastAbsorbSync }) => (
  get(lastAbsorbSync, 'properties.status') === ORG_ACTIVITY_STATUSES.IN_PROGRESS
);

const fulfilled = (state, { payload }) => ({
  ...state,
  organization: {
    ...state.organization,
    activity: payload,
  },
  loading: {
    ...state.loading,
    adpSync: state.organization.activity ? state.loading.adpSync : isAdpSyncInProgress(payload),
    enrollment: state.organization.activity ? state.loading.enrollment : isEnrollmentInProgress(payload),
    absorbSync: state.organization.activity ? state.loading.absorbSync : isAbsorbSyncInProgress(payload),
  },
});

const defaultExport = { fulfilled };

export default defaultExport;
