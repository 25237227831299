import {
  LOGOUT_USER,
  FETCH_USER,
} from 'utils/actionTypes';
import {
  logoutUser as logoutUserMethod,
  fetchUser as fetchUserMethod,
} from 'utils/api';
import { ADP_LOGOUT_URL, PUBLIC_URL } from 'utils/constants';

export const fetchUser = () => (dispatch) => {
  return fetchUserMethod().then(user => {
    dispatch({
      type: `${FETCH_USER}_FULFILLED`,
      user,
    });

    return user;
  }).catch(() => {
    dispatch({
      type: `${FETCH_USER}_REJECTED`,
    });
  });
}

export const logoutUser = () => (dispatch, getState) => {
  return logoutUserMethod().then(() => {
    dispatch({
      type: `${LOGOUT_USER}_FULFILLED`,
    });
    // Redirect to ADP logout url
    window.location.href = `${ADP_LOGOUT_URL}?post_logout_redirect_uri=${PUBLIC_URL}/login`;
  });
};
