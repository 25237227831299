import queryString from 'query-string';
import { API_BASE } from './constants';
import { getSessionToken } from './session';
import { LOGIN_ROUTE } from './routes';

const defaultOptions = {
  redirectOnUnauthorized: true
};

export const get = async (path, params, { redirectOnUnauthorized } = defaultOptions) => {
  const headers = { 'content-type': 'application/json' };
  const sessionToken = getSessionToken();
  if (sessionToken) { headers.authorization = `Bearer ${sessionToken}` }
  const url = `${API_BASE}${path}${params ? '?' + queryString.stringify(params) : ''}`;

  const response = await fetch(
    url,
    { method: 'GET', headers }
  ).then(res => {
    if (redirectOnUnauthorized && res.status === 401) {
      // The API call returned Unathorized, redirect user to the login page
      return document.location.href = LOGIN_ROUTE;
    }
    if (!res.ok) { return Promise.reject(res) }

    return res;
  });

  return response.json();
}

export const post = async (path, body, { redirectOnUnauthorized } = defaultOptions) => {
  const headers = { 'content-type': 'application/json' };
  const sessionToken = getSessionToken();
  if (sessionToken) { headers.authorization = `Bearer ${sessionToken}` }
  const url = `${API_BASE}${path}`;

  const response = await fetch(
    url,
    {
      method: 'POST',
      body: body ? JSON.stringify(body) : null,
      headers,
    }
  ).then(res => {
    if (redirectOnUnauthorized && res.status === 401) {
      // The API call returned Unathorized, redirect user to the login page
      return document.location.href = LOGIN_ROUTE;
    }
    if (!res.ok) { return Promise.reject(res) }

    return res;
  });

  return response.json();
}

