import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from 'actions';
import { connect } from 'react-redux';
import Dashboard from 'containers/Dashboard';
import Login from 'containers/Login';
import LoginConfirmation from 'containers/LoginConfirmation';
import Logout from './Logout';
import AdpSSOLogin from './AdpSSOLogin';
import AdpSSOForwarder from './AdpSSOForwarder';
import SetupWizardCallback from './SetupWizardCallback';
import GlobalAlert from 'components/GlobalAlert';
import {
  LOGIN_ROUTE,
  LOGIN_CONFIRMATION_ROUTE,
  DASHBOARD_ROUTE,
  ADP_SSO_LOGIN_ROUTE,
  ADP_SSO_FORWARDER_ROUTE,
  LOGOUT_ROUTE,
  SETUP_WIZARD_CALLBACK_ROUTE,
} from 'utils/routes';

/**
 * Main component which contains routing, and wraps other full-page components
 */
class App extends React.Component {
  componentDidMount() {
    const { actions: { fetchUser } } = this.props;

    fetchUser();
  }

  get globalAlert() {
    const { globalAlert } = this.props;
    if (!globalAlert) return null;

    return(
      <GlobalAlert text={globalAlert.message} variant={globalAlert.variant}/>
    );
  }

  render() {
    return (
      <Router>
        { this.globalAlert }
        <Switch>
          <Route
            path={DASHBOARD_ROUTE}
            render={(routerProps) => <Dashboard {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={LOGIN_ROUTE}
            render={(routerProps) => <Login {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={LOGIN_CONFIRMATION_ROUTE}
            render={(routerProps) => <LoginConfirmation {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={LOGOUT_ROUTE}
            render={(routerProps) => <Logout {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={ADP_SSO_FORWARDER_ROUTE}
            render={(routerProps) => <AdpSSOForwarder {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={ADP_SSO_LOGIN_ROUTE}
            render={(routerProps) => <AdpSSOLogin {...this.props} {...routerProps} />}
          />
          <Route
            exact={true}
            path={SETUP_WIZARD_CALLBACK_ROUTE}
            render={(routerProps) => <SetupWizardCallback {...this.props} {...routerProps} />}
          />
          <Redirect to={LOGIN_ROUTE} />
        </Switch>
      </Router>
    );
  }
}

export default connect(
  (state) => ({...state.app}),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
  }),
)(App);
