import React from 'react';
import Spinner from 'components/Spinner';

/**
 * Component that handles logout callback from Absorb
 */
class Logout extends React.Component {
  componentDidMount() {
    const { actions: { logoutUser } } = this.props;
    logoutUser();
  }

  render() {
    return(<Spinner />);
  }
}

export default Logout;
