export const LOGIN_ROUTE = '/login';
export const LOGIN_CONFIRMATION_ROUTE = '/login_confirmation';
export const LOGOUT_ROUTE = '/logout';
export const DASHBOARD_ROUTE = '/dashboard';
export const ADP_SSO_LOGIN_ROUTE = '/adp_login';
export const ADP_SSO_FORWARDER_ROUTE = '/adp_sso_forwarder';
export const SETTINGS_ROUTE = '/dashboard/settings';
export const ACCOUNT_ROUTE = '/dashboard/account';
export const SETUP_WIZARD_ROUTE = '/dashboard/setup_wizard';
export const SETUP_WIZARD_CALLBACK_ROUTE = '/setup_wizard_callback';
