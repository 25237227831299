import React from 'react';
import classNames from 'classnames';
import { BsCaretDown, BsCaretUp, BsPerson } from 'react-icons/bs';
import { ACCOUNT_ROUTE } from 'utils/routes';

/**
 * Component containing the profile portion of the top bar
 */
class Profile extends React.Component {
  constructor() {
    super();

    this.state = { showMenu: false }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!this.ref.contains(e.target)) {
      this.setState({ showMenu: false });
    }
  }

  handleClickAccount = () => {
    const { history } = this.props;

    history.push(ACCOUNT_ROUTE);
    this.setState({ showMenu: false });
  }

  handleClickLogout = () => {
    const { actions: { logoutUser } } = this.props;

    logoutUser();
  }

  get profile() {
    if (this.props.logoOnly) return null;

    return (
      <div
        className='top-bar__profile'
        onClick={() => this.setState({ showMenu: !this.state.showMenu })}
      >
        <div className={
          classNames('top-bar__profile-icon-container')
        }>
          <BsPerson className='top-bar__profile-icon' size='2em' />
        </div>
        <div className='top-bar__profile-name'>
          { this.props.user.firstName } { this.props.user.lastName }
        </div>
        {
          this.state.showMenu
          ? <BsCaretUp className='top-bar__profile-caret' size='16px' />
          : <BsCaretDown className='top-bar__profile-caret' size='16px' />
        }
      </div>
    );
  }

  get profileMenu() {
    return(
      <ul className='top-bar__profile-menu'>
        <li
          className='top-bar__profile-menu-item'
          onClick={this.handleClickAccount}
        >
          Account
        </li>
        <li
          className='top-bar__profile-menu-item'
          onClick={this.handleClickLogout}
        >
          Log out
        </li>
      </ul>
    );
  }

  render() {
    return(
      <div
        className={classNames('top-bar__column profile', { 'show-menu': this.state.showMenu })}
        ref={node => this.ref = node}
      >
        { this.profile }
        { this.profileMenu }
      </div>
    );
  }
}

export default Profile;
