import React from 'react';
import { PieChart as RMPieChart } from 'react-minimal-pie-chart';
import './index.css';

function PieChart({ enrollments }) {
  const data = [
    {
      color: '#1392BE',
      title: 'Completed',
      value: enrollments.reduce(
        (acc, current) => acc + (current.status === 'Completed' ? 1 : 0),
        0
      ),
    },
    {
      color: '#FD394F',
      title: 'In progress',
      value: enrollments.reduce(
        (acc, current) => acc + (current.status === 'In progress' ? 1 : 0),
        0
      ),
    },
    {
      color: '#BDBDBD',
      title: 'Not started',
      value: enrollments.reduce(
        (acc, current) => acc + (current.status === 'Not started' ? 1 : 0),
        0
      ),
    },
    {
      color: '#FFD300',
      title: 'Inactive',
      value: enrollments.reduce(
        (acc, current) => acc + (current.status === 'Inactive' ? 1 : 0),
        0
      ),
    }
  ].filter(s => s.value > 0);

  return(
    <RMPieChart
      className='pie-chart'
      center={[
        60,
        60
      ]}
      data={data}
      lengthAngle={360}
      lineWidth={30}
      paddingAngle={0}
      radius={50}
      startAngle={0}
      viewBoxSize={[
        120,
        120
      ]}
    />
  );
}

export default PieChart;
