import { getEnrollments } from './enrollment';
import { addGlobalAlert } from './app';
import {
  getOrganizationActivity,
  getOrganizationAccount,
  getOrganizationSettings,
  getOrganizationConfig,
  getSetupWizard,
  setupPusher,
} from './organization';

export const fetchInitialData = () => (dispatch, getState) => {
  return getOrganizationConfig()(dispatch).then(() => setupPusher()(dispatch, getState)).then(() => Promise.all([
    getEnrollments()(dispatch),
    getSetupWizard()(dispatch),
    getOrganizationActivity()(dispatch),
    getOrganizationAccount()(dispatch),
    getOrganizationSettings()(dispatch),
  ])).catch(e => {
    addGlobalAlert({ message: 'Unknown error. Please try again or contact support', variant: 'danger' })(dispatch);
    return Promise.reject(e);
  });
}
