import React from 'react';
import { adminUserIsNotAuthenticated } from 'utils/auth';
import TopBar from 'components/TopBar';
import LoginBox from './LoginBox';
import './index.css';

/**
 * Component containing the Login page
 */
function Login(props) {
  return (
    <div className='login'>
      <TopBar logoOnly={true} actions={props.actions} />
      <div className='login__content'>
        <LoginBox {...props} />
      </div>
    </div>
  );
}

export default adminUserIsNotAuthenticated(Login);
