import React from 'react';
import classNames from 'classnames';
import './index.css';

/**
 * Reusable component containing a checkbox and its label
 */
const Checkbox = (props) => {
  return(
    <div
      className={classNames('checkbox', { 'half-checked': props.halfChecked }) }
      style={props.style}
    >
      <input
        id={props.id}
        type='checkbox'
        disabled={props.disabled}
        className='checkbox__input'
        onChange={props.onChange}
        checked={props.checked}
      />
      <label htmlFor={props.id} className='checkbox__label'>{props.label}</label>
    </div>
  );
}

export default Checkbox;
