import React from 'react';
import ReactDOM from 'react-dom';
import store from 'utils/store';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from 'containers/App';
import { initDatadog } from './utils/datadog';

if (process.env.NODE_ENV === 'production') {
  initDatadog();
}

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
