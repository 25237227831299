import { get, post } from '../ajax';
import { loginUserWithAdp } from './user';
import {
  ADP_GET_AUTHORIZE_URL_PATH,
  ADP_SYNC_PATH,
  ADP_VERIFY_SSO_PATH,
  ADP_CODE_LISTS_PATH,
} from './paths';

export const adpGetAuthorizeUrl = async () => {
  return get(ADP_GET_AUTHORIZE_URL_PATH).then(({ url }) => url);
}

export const adpSync = async (params) => {
  return post(ADP_SYNC_PATH, params);
}

export const adpGetCodeLists = async () => {
  return get(ADP_CODE_LISTS_PATH);
}

export const handleAdpLogin = async (authorizationCode, ssoState) => {
  return post(ADP_VERIFY_SSO_PATH, { state: ssoState }).then(({ success }) => {
    if (success) {
      return loginUserWithAdp({ authorizationCode });
    } else {
      throw new Error({ statu: 401 });
    }
  });
}
