import React from 'react';

/**
 * Reusable component containing text input used for search
 */
function SearchInput(props) {
  return(
    <div className='search-input'>
      <input
        className='search-input__input'
        type='text'
        value={props.value}
        onChange={props.onChange}
        name='search'
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default SearchInput;
