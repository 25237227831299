import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaCheck } from 'react-icons/fa';
import { mapValues, pickBy, groupBy } from 'lodash';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Select from 'react-select';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import Group from './Group';
import './index.css';

class EnrollModal extends React.Component {
  constructor(props) {
    super(props);

    const { users: { groupedById, allUsers } } = props;
    this.state = {
      submitted: false,
      formValues: mapValues(groupedById, (user) => false),
      currentGroup: 'department',
      groups: {
        department: mapValues(
          groupBy(allUsers, user => this.groupItemLabel(user, 'department')),
          users => ({ collapsed: true, users }),
        ),
        businessUnit: mapValues(
          groupBy(allUsers, user => this.groupItemLabel(user, 'businessUnit')),
          users => ({ collapsed: true, users }),
        ),
        costNumber: mapValues(
          groupBy(allUsers, user => this.groupItemLabel(user, 'costNumber')),
          users => ({ collapsed: true, users }),
        ),
        workLocation: mapValues(
          groupBy(allUsers, user => this.groupItemLabel(user, 'workLocation')),
          users => ({ collapsed: true, users }),
        ),
      },
    }
  }

  groupItemLabel(user, group) {
    const nullNames = {
      department: 'No Department',
      businessUnit: 'No Business Unit',
      costNumber: 'No Cost Number',
      workLocation: 'No Location',
    }
    const value = user[group];
    if (!value) return nullNames[group];
    if (!value.name) return value.codeValue;

    return `${value.codeValue} - ${value.name}`;
  }

  enrollUsers = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { actions: { enrollUsers }, isWizardStep } = this.props;

    const selectedUserIds = Object.keys(pickBy(this.state.formValues));
    return enrollUsers({ userIds: selectedUserIds, isWizardStep });
  }

  toggleUserChecked = (userId) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [userId]: !this.state.formValues[userId]
      }
    });
  }

  toggleAllUsers = ({ selectAll }) => {
    const { users: { groupedById } } = this.props;

    if (selectAll) {
      this.setState({
        formValues: mapValues(this.state.formValues, (_, id) => groupedById[id].canBeEnrolled ? true : false)
      });
    } else {
      this.setState({
        formValues: mapValues(this.state.formValues, () => false)
      });
    }
  }

  toggleCheckedGroupItem = ({ selectAll, groupName, groupItemLabel }) => {
    const { users: { groupedById } } = this.props;

    if (selectAll) {
      this.setState({
        formValues: mapValues(this.state.formValues, (currentValue, id) => {
          const user = groupedById[id];
          if (!user.canBeEnrolled) return false;
          if (this.groupItemLabel(user, groupName) === groupItemLabel) return true;
          return currentValue;
        })
      });
    } else {
      this.setState({
        formValues: mapValues(this.state.formValues, (currentValue, id) => {
          const user = groupedById[id];
          if (!user.canBeEnrolled) return false;
          if (this.groupItemLabel(user, groupName) === groupItemLabel) return false;
          return currentValue;
        })
      });
    }
  }

  toggleCollapseSection = ({ groupName, groupItemLabel }) => {
    const { collapsed } = this.state.groups[groupName][groupItemLabel];

    this.setState({
      groups: {
        ...this.state.groups,
        [groupName]: {
          ...this.state.groups[groupName],
          [groupItemLabel]: {
            ...this.state.groups[groupName][groupItemLabel],
            collapsed: !collapsed,
          }
        }
      }
    })
  }

  isUserChecked = (userId) => {
    const { formValues } = this.state;
    return formValues[userId];
  }

  group(groupName) {
    const groupItems = this.state.groups[groupName];

    return(
      <Group
        groupName={groupName}
        groupItems={groupItems}
        isUserChecked={this.isUserChecked}
        toggleCheckedGroupItem={this.toggleCheckedGroupItem}
        toggleUserChecked={this.toggleUserChecked}
        toggleCollapseSection={this.toggleCollapseSection}
      />
    );
  }

  get submittedBody() {
    return(
      <div className='dashboard__modal-submitted-body'>
        <FaCheck color='#28a745' size='6em' />
        <p className='dashboard__modal-submitted-message'>
          Enrolled employees will receive an email with instructions on accessing their learning account.
        </p>

        <Button
          rounded
          fullWidth
          text='Close'
          onClick={this.props.onHide}
        />
      </div>
    );
  }

  get noGroup() {
    const { users: { allUsers } } = this.props;
    const { formValues } = this.state;

    return(
      <>
        {
          allUsers.map((user, idx) => (
            <li className='dashboard__modal-list-item' key={idx}>
              <Checkbox
                id={`checkbox-${user.id}-${idx}`}
                label={`${user.firstName} ${user.lastName}`}
                disabled={!user.canBeEnrolled}
                checked={formValues[user.id]}
                onChange={() => this.toggleUserChecked(user.id)}
              />
              <div>{user.email}</div>
            </li>
          ))
        }
      </>
    );
  }

  get allCheckboxes() {
    const { currentGroup } = this.state;
    if (!currentGroup) return this.noGroup;

    return this.group(currentGroup);
  }

  get groupBySelectOptions() {
    return [
      ['No grouping', null],
      ['Department', 'department'],
      ['Business unit', 'businessUnit'],
      ['Cost number', 'costNumber'],
      ['Location', 'workLocation'],
    ].map(pair => ({ label: pair[0], value: pair[1] }));
  }

  get cannotBeEnrolledWarning() {
    const { users: { allUsers } } = this.props;
    const showEmailWarning = allUsers.some(user => !user.canBeEnrolled);
    if (!showEmailWarning) return null;

    return (
      <p className='dashboard__modal-subheader'>
        <BsExclamationTriangleFill size='1.5em' color='#fac241' />
        A business email is required for enrollment. Users without an email will appear gray in the list below.
      </p>
    );
  }

  get availableSubscripionEmployees() {
    const {
      organization: { account: { subscriptionNumUsers, enrolledUsersCount } }
    } = this.props;
    return subscriptionNumUsers - enrolledUsersCount;
  }

  get formBody() {
    const { formValues } = this.state;
    const { users: { groupedById, allUsers } } = this.props;
    const allSelected = Object.keys(formValues).every(id => groupedById[id].canBeEnrolled ? this.isUserChecked(id) : true);
    const noneSelected = Object.values(formValues).every(el => !el);

    return(
      <div>
        <h2 className='dashboard__modal-header'>Select users to enroll</h2>
        { this.cannotBeEnrolledWarning }
        <div className='dashboard__modal-available-subscription-users'>
          Available course seats: { this.availableSubscripionEmployees }
        </div>
        <ul className='dashboard__modal-list'>
          {
            allUsers.length
            ? <>
                <li className='dashboard__modal-list-item'>
                  <Checkbox
                    id={`checkbox-select-deselect-all`}
                    label={`Select / Deselect all`}
                    halfChecked={!allSelected && !noneSelected}
                    checked={allSelected}
                    onChange={() => this.toggleAllUsers({ selectAll: noneSelected })}
                  />
                  <Select
                    styles={{ container: (provided) => ({ ...provided, width: '20%' }) }}
                    defaultValue={this.groupBySelectOptions[1]}
                    options={this.groupBySelectOptions}
                    onChange={({ value }) => this.setState({ currentGroup: value })}
                  />
                </li>
                <hr />
                { this.allCheckboxes }
              </>
            : 'No users to enroll.'
          }
        </ul>
        { this.submitButton }
      </div>
    );
  }

  get submitButton() {
    const { submitted } = this.state;
    const { users: { allUsers } } = this.props;
    const numUsersToEnroll = allUsers.filter(user => this.isUserChecked(user.id)).length;

    let buttonText;
    let disabled;
    if (numUsersToEnroll > this.availableSubscripionEmployees) {
      buttonText = `Please select ${this.availableSubscripionEmployees} or fewer employees to enroll`;
      disabled = true;
    } else if (submitted) {
      buttonText = `Enrolling ${numUsersToEnroll} ${numUsersToEnroll === 1 ? 'Employee' : 'Employees'}`;
      disabled = true;
    } else if (numUsersToEnroll === 0) {
      buttonText = `Enroll ${numUsersToEnroll} ${numUsersToEnroll === 1 ? 'Employee' : 'Employees'}`;
      disabled = true;
    } else {
      buttonText = `Enroll ${numUsersToEnroll} ${numUsersToEnroll === 1 ? 'Employee' : 'Employees'}`;
      disabled = false;
    }

    return(
      <Button
        rounded
        fullWidth
        disabled={disabled}
        text={buttonText}
        type='submit'
      />
    );
  }

  render() {
    const { submitted } = this.state;

    return(
      <Modal
        show={true}
        onHide={this.props.onHide}
        centered
        size={submitted ? 'm' : 'xl'}
      >
        <form onSubmit={this.enrollUsers}>
          { submitted ? this.submittedBody : this.formBody }
        </form>
      </Modal>
    );
  }
}

EnrollModal.defaultProps = {
  isWizardStep: false,
}

export default EnrollModal;
