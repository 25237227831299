import React from 'react';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import './index.css';

/**
 * Reusable component containing a button with a spinner
 */
class ButtonWithSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false }
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    this.setState({ loading: true });

    onClick(e)
      .then(() => this._isMounted && this.setState({ loading: false })) // don't set state on unmounted component
      .catch(() => this.setState({ loading: false }));
  }

  get spinner() {
    return(
      <div className='button__spinner-container'>
        <Spinner />
      </div>
    );
  }

  render() {
    const {
      rounded, disabled, fullWidth,
      type, text, style
    } = this.props;

    const conditionalClassNames = {
      rounded: rounded,
      disabled: disabled,
      'full-width': fullWidth,
    };

    return(
      <div style={style} className={classNames('button with-spinner', conditionalClassNames)}>
        <input
          className='button__input'
          type={type}
          value={text}
          onClick={this.handleClick}
          disabled={disabled}
        />
        { this.state.loading ? this.spinner : null }
      </div>
    );
  }
}

ButtonWithSpinner.defaultProps = {
  rounded: false,
  disabled: false,
  type: 'button',
};

export default ButtonWithSpinner;
