import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Fuse from 'fuse.js';
import { SearchInput } from 'components/Inputs';
import PieChart from 'components/PieChart';
import ButtonWithSpinner from 'components/ButtonWithSpinner';
import Badge from 'components/Badge';
import EnrollModal from '../EnrollModal';
import { absorbGetUnenrolledUsers } from 'utils/api';
import './index.css';

class Enrollments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enrollments: props.enrollments,
      showEnrollModal: false,
      usersToEnroll: [],
    }
    this.fuse = new Fuse(props.enrollments, {
      keys: ['name', 'email'],
      findAllMatches: true,
      ignoreLocation: true,
      threshold: 0, // Exact matches only, no fuzzy search
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.enrollments.length !== prevProps.enrollments.length) {
      this.setState({ enrollments: this.props.enrollments });
      this.fuse.setCollection(this.props.enrollments);
    }
  }

  onUserFilterChange = (e) => {
    const { value } = e.target;
    const filteredEnrollments =
      value ? this.fuse.search(value.trim()).map(match => match.item) : this.props.enrollments;
    this.setState({ enrollments: filteredEnrollments });
  }

  get enrollModal() {
    if (!this.state.showEnrollModal) return null;

    return(
      <EnrollModal
        actions={this.props.actions}
        users={this.state.unenrolledUsers}
        onHide={() => this.setState({ showEnrollModal: false })}
        organization={this.props.organization}
      />
    );
  }

  get noResults() {
    return <div className='dashboard__enrollments-no-results'>No records to show.</div>;
  }

  get enrollmentsList() {
    return(
      <div className='dashboard__enrollments'>
        <div className='dashboard__enrollments-top-section'>
          <h2 className='dashboard__enrollments-header'>Training summary for enrolled employees</h2>
          { this.search }
        </div>
        <div className='dashboard__enrollments-table-header'>
          <div className='dashboard__enrollments-table-header-cell'>Name</div>
          <div className='dashboard__enrollments-table-header-cell'>Email</div>
          <div className='dashboard__enrollments-table-header-cell'>Course</div>
          <div className='dashboard__enrollments-table-header-cell'>Status</div>
          <div className='dashboard__enrollments-table-header-cell'>Progress</div>
        </div>
        <div className='dashboard__enrollments-table-contents'>
          {
            this.state.enrollments.length
            ? this.state.enrollments.map((item, idx) => (
                <div key={idx} className='dashboard__enrollments-table-row'>
                  <div className='dashboard__enrollments-table-cell'>{item.name}</div>
                  <div className='dashboard__enrollments-table-cell'>{item.email}</div>
                  <div className='dashboard__enrollments-table-cell'>{item.courseName}</div>
                  <div className='dashboard__enrollments-table-cell'>{item.status}</div>
                  <div className='dashboard__enrollments-table-cell'>
                    <ProgressBar now={item.progress} />
                  </div>
                </div>
              ))
            : this.noResults
          }
        </div>
      </div>
    );
  }

  get search() {
    if (this.props.logoOnly) return null;

    return (
      <SearchInput
      placeholder='Filter by name/email'
        onChange={this.onUserFilterChange}
      />
    );
  }

  get rightAside() {
    return(
      <div className='dashboard__right-aside'>
        <div className='dashboard__users'>
          <div className='dashboard__users-header'>
            <h2>Total Enrollments</h2>
            <span className='dashboard__users-number'>{this.props.enrollments.length}</span>
          </div>
          <PieChart enrollments={this.props.enrollments} />
          <div className='dashboard__users-badges'>
            <div className='dashboard__users-badges-row'>
              <Badge text={`Completed ${this.numEnrollmentsByStatus('Completed')}`} color='blue' />
              <Badge text={`In progress ${this.numEnrollmentsByStatus('In progress')}`} color='red' />
            </div>
            <div className='dashboard__users-badges-row'>
              <Badge text={`Not started ${this.numEnrollmentsByStatus('Not started')}`} color='gray' />
              <Badge text={`Inactive ${this.numEnrollmentsByStatus('Inactive')}`} color='yellow' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  get enrollButton() {
    return (
      <ButtonWithSpinner
        text='Enroll more'
        onClick={() => absorbGetUnenrolledUsers().then(unenrolledUsers => (
          this.setState({ showEnrollModal: true, unenrolledUsers })
        ))}
      />
    );
  }

  numEnrollmentsByStatus(status) {
    const { enrollments } = this.props;

    return enrollments.reduce(
      (acc, current) => acc + (current.status === status ? 1 : 0),
      0
    );
  }

  render() {
    return(
      <div className='dashboard__content'>
        <div className='dashboard__main'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className='dashboard__header'>Welcome {this.props.user.firstName}!</h1>
            { this.enrollButton }
          </div>
          { this.enrollmentsList }
        </div>
        { this.rightAside }
        { this.enrollModal }
      </div>
    );
  }
}

export default Enrollments;
