export const CONTACT_US_URL = 'https://www.ryleylearning.com/contact';
export const ADP_MANAGE_APPS_URL = 'https://apps.adp.com/account/apps';
export const ADP_MANAGE_USERS_URL = 'https://apps.adp.com/account/users';
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN || 'http://localhost:3001/dev';
export const API_BASE = `${API_ORIGIN}/api`;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const ADP_LOGOUT_URL = 'https://accounts.adp.com/auth/oauth/v2/logout';
const ADP_CONSUMER_APPLICATION_ID = process.env.REACT_APP_ADP_CONSUMER_APPLICATION_ID;
export const ADP_CONSENT_MANAGER_PENDING_URL =
  `https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=${ADP_CONSUMER_APPLICATION_ID}`;
export const WIZARD_STEP_NAMES = {
  CONSENT: 'consent',
  ADP_SYNC: 'adp-sync',
  ENROLLMENT: 'enrollment',
  ABSORB_SYNC: 'absorb-sync',
  AUTO_ENROLLMENT_RULE: 'auto-enrollment-rule',
};
export const ORG_ACTIVITY_STATUSES = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  IN_PROGRESS: 'in_progress'
}
