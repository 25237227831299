import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { get } from 'lodash';
import { adminUserIsAuthenticated } from 'utils/auth';
import TopBar from 'components/TopBar';
import Sidebar from './Sidebar';
import Enrollments from './Enrollments';
import Settings from './Settings';
import Account from './Account';
import SetupWizard from './SetupWizard';
import {
  SETTINGS_ROUTE,
  DASHBOARD_ROUTE,
  ACCOUNT_ROUTE,
  SETUP_WIZARD_ROUTE,
} from 'utils/routes';
import Spinner from 'components/Spinner';
import './index.css';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      wizardChecked: false,
    }
  }

  componentDidMount() {
    const {
      actions: { fetchInitialData },
      history,
    } = this.props;

    fetchInitialData().then(() => {
      const { organization: { setupWizard } } = this.props;
      if (!setupWizard.complete) history.push(SETUP_WIZARD_ROUTE);
      this.setState({ wizardChecked: true });
    });
  }

  get componentReady() {
    const { enrollments, organization } = this.props;
    const dataReady = [
      enrollments,
      get(organization, 'account'),
      get(organization, 'settings'),
      get(organization, 'activity'),
      get(organization, 'config'),
      get(organization, 'setupWizard'),
    ].every(prop => !!prop);
    const { wizardChecked } = this.state;
    return dataReady && wizardChecked;
  }

  render() {
    if (!this.componentReady) {
      return (<Spinner />);
    }
    const { organization: { setupWizard } } = this.props;

    return (
      <div className='dashboard'>
        <TopBar {...this.props}/>
        <div className='dashboard__flex-container'>
          <Sidebar setupWizard={!setupWizard.complete}/>
          <Switch>
            <Route
              exact={true}
              path={DASHBOARD_ROUTE}
              render={(routerProps) => <Enrollments {...this.props} {...routerProps} />}
            />
            <Route
              exact={true}
              path={SETTINGS_ROUTE}
              render={(routerProps) => <Settings {...this.props} {...routerProps} />}
            />
            <Route
              exact={true}
              path={ACCOUNT_ROUTE}
              render={(routerProps) => <Account {...this.props} {...routerProps} />}
            />
            <Route
              exact={true}
              path={SETUP_WIZARD_ROUTE}
              render={(routerProps) => <SetupWizard {...this.props} {...routerProps} />}
            />
            <Redirect to={DASHBOARD_ROUTE} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default adminUserIsAuthenticated(Dashboard);
