import React from 'react';
import classNames from 'classnames';
import './index.css';

/**
 * Reusable component containing a button
 */
function Button(props) {
  const conditionalClassNames = { rounded: props.rounded, disabled: props.disabled, 'full-width': props.fullWidth };

  return(
    <div className={classNames('button', conditionalClassNames)}>
      <input
        className='button__input'
        type={props.type || 'button'}
        value={props.text}
        onClick={props.onClick}
        disabled={props.disabled}
      />
    </div>
  );
}

Button.defaultProps = {
  rounded: false,
  disabled: false
};

export default Button;
