import React from 'react';
import classnames from 'classnames';
import { FaCheck } from 'react-icons/fa';
import ButtonWithSpinner from 'components/ButtonWithSpinner';
import Button from 'components/Button';
import LoadingIcon from 'components/LoadingIcon';
import AutoEnrollModal from '../Settings/AutoEnrollModal';
import EnrollModal from '../EnrollModal';
import { absorbGetUnenrolledUsers } from 'utils/api';
import { WIZARD_STEP_NAMES } from 'utils/constants';
import { DASHBOARD_ROUTE } from 'utils/routes';
import './index.css';

class SetupWizard extends React.Component {
  constructor() {
    super();

    this.state = {
      showEnrollModal: false,
      showAutoEnrollModal: false,
      unenrolledUsers: [],
    }
  }

  componentDidMount() {
    const {
      history,
      organization: { setupWizard },
    } = this.props;

    if (setupWizard.complete) history.push(DASHBOARD_ROUTE);
  }

  isDisabledStep = (step) => {
    return !step.complete && !step.active;
  }

  consentStep({ step, position }) {
    return(
      <div key={position} className={classnames('dashboard__wizard-step', { disabled: this.isDisabledStep(step) })}>
        <div className='dashboard__wizard-step-position'><i>{ position }</i></div>
        <div className='dashboard__wizard-step-content'>
          <div className='dashboard__wizard-step-header'>Provide consent to access your data</div>
          <div className='dashboard__wizard-step-info'>
            Providing consent allows Ryley Learning to access data form your ADP account.
            The consent can be given by the ADP practitioner who purchased the subscription.
          </div>
        </div>
        <div className='dashboard__wizard-step-cta'>
          { step.complete ? this.completeIcon : this.consentButton }
        </div>
      </div>
    );
  }

  adpSyncStep({ step, position }) {
    return(
      <div key={position} className={classnames('dashboard__wizard-step', { disabled: this.isDisabledStep(step) })}>
        <div className='dashboard__wizard-step-position'><i>{ position }</i></div>
        <div className='dashboard__wizard-step-content'>
          <div className='dashboard__wizard-step-header'>Import ADP data</div>
          <div className='dashboard__wizard-step-info'>
            The initial import may take up to 15 minutes depending on the size of your organization.
            Afterwards, any changes in your ADP account will automatically sync with Ryley Learning.
          </div>
        </div>
        <div className='dashboard__wizard-step-cta'>
          { step.complete ? this.completeIcon : this.adpSyncButton }
        </div>
      </div>
    );
  }

  enrollmentStep({ step, position }) {
    return(
      <div key={position} className={classnames('dashboard__wizard-step', { disabled: this.isDisabledStep(step) })}>
        <div className='dashboard__wizard-step-position'><i>{ position }</i></div>
        <div className='dashboard__wizard-step-content'>
          <div className='dashboard__wizard-step-header'>Enroll employees in a Ryley Learning course</div>
          <div className='dashboard__wizard-step-info'>
            The course selection is automatic and based on each employee's work location.
            You may enroll more employees at any time from your application dashboard.
          </div>
        </div>
        <div className='dashboard__wizard-step-cta'>
          { step.complete ? this.completeIcon : this.enrollButton }
        </div>
      </div>
    );
  }

  absorbSyncStep({ step, position }) {
    return(
      <div key={position} className={classnames('dashboard__wizard-step', { disabled: this.isDisabledStep(step) })}>
        <div className='dashboard__wizard-step-position'><i>{ position }</i></div>
        <div className='dashboard__wizard-step-content'>
          <div className='dashboard__wizard-step-header'>Sync existing Absorb users</div>
          <div className='dashboard__wizard-step-info'>
            This step will bring employees that are already enrolled in Absorb into your new Ryley Learning application dashboard.
          </div>
        </div>
        <div className='dashboard__wizard-step-cta'>
          { step.complete ? this.completeIcon : this.absorbSyncButton }
        </div>
      </div>
    );
  }

  autoEnrollmentRuleStep({ step, position }) {
    return(
      <div key={position} className={classnames('dashboard__wizard-step', { disabled: this.isDisabledStep(step) })}>
        <div className='dashboard__wizard-step-position'><i>{ position }</i></div>
        <div className='dashboard__wizard-step-content'>
          <div className='dashboard__wizard-step-header'>Configure future automatic enrollments</div>
          <div className='dashboard__wizard-step-info'>
            Enrollment rule applies to employees whose records change with respect to the selected criteria.
            If you select all available criteria, the rule will apply if any of the following properties change: department, business unit, work location or cost number.
          </div>
        </div>
        <div className='dashboard__wizard-step-cta'>
          { step.complete ? this.completeIcon : this.autoEnrollButton }
        </div>
      </div>
    );
  }

  step({ step, position }) {
    let stepContent;
    switch(step.name) {
      case WIZARD_STEP_NAMES.CONSENT:
        stepContent = this.consentStep({ step, position });
        break;
      case WIZARD_STEP_NAMES.ADP_SYNC:
        stepContent = this.adpSyncStep({ step, position });
        break;
      case WIZARD_STEP_NAMES.ENROLLMENT:
        stepContent = this.enrollmentStep({ step, position });
        break;
      case WIZARD_STEP_NAMES.ABSORB_SYNC:
        stepContent = this.absorbSyncStep({ step, position });
        break;
      case WIZARD_STEP_NAMES.AUTO_ENROLLMENT_RULE:
        stepContent = this.autoEnrollmentRuleStep({ step, position });
        break;
      default:
        return null;
    }
    return stepContent;
  }

  get header() {
    return(
      <div>
        <h1 className='dashboard__header'>Setup Wizard</h1>
      </div>
    );
  }

  get subheader() {
    return(
      <div className='dashboard__main-subheader'>
        Please complete the steps below to finish setting up your account.
        You may close your browser and return to this wizard at any time.
      </div>
    );
  }

  get content() {
    const { organization: { setupWizard: { steps } } } = this.props;

    return (
      <div className='dashboard__wizard-steps'>
        { steps.map((step, idx) => this.step({ step, position: idx + 1 })) }
      </div>
    );
  }

  get completeIcon() {
    return <FaCheck color='#28a745' size='3em' />;
  }

  get autoEnrollButton() {
    const { actions: { adpGetCodeLists } } = this.props;

    return(
      <ButtonWithSpinner
        text='Start'
        onClick={() => adpGetCodeLists().then(() => this.setState({ showAutoEnrollModal: true }))}
      />
    );
  }

  get enrollButton() {
    const {
      loading: { enrollment: enrollmentInProgress },
    } = this.props;
    if (enrollmentInProgress) {
      return <LoadingIcon />;
    }
    return (
      <ButtonWithSpinner
        text='Start'
        onClick={() => absorbGetUnenrolledUsers().then(unenrolledUsers => (
          this.setState({ showEnrollModal: true, unenrolledUsers })
        ))}
      />
    );
  }

  get absorbSyncButton() {
    const {
      actions: { absorbSync },
      loading: { absorbSync: absorbSyncInProgress },
    } = this.props;
    if (absorbSyncInProgress) {
      return <LoadingIcon />;
    }

    return (
      <Button
        text='Start'
        onClick={() => absorbSync({ isWizardStep: true })}
      />
    );
  }

  get adpSyncButton() {
    const {
      actions: { adpSync },
      loading: { adpSync: adpSyncInProgress },
    } = this.props;
    if (adpSyncInProgress) {
      return <LoadingIcon />;
    }

    return (
      <Button
        text='Start'
        onClick={() => adpSync({ isWizardStep: true })}
      />
    );
  }

  get consentButton() {
    const {
      loading: { adpConsent: adpConsentInProgress },
    } = this.props;
    if (adpConsentInProgress) {
      return <LoadingIcon />;
    }
    return (
      <Button
        text='Start'
        onClick={this.props.actions.getAdpConsent}
      />
    );
  }

  get enrollModal() {
    if (!this.state.showEnrollModal) return null;

    return(
      <EnrollModal
        actions={this.props.actions}
        users={this.state.unenrolledUsers}
        onHide={() => this.setState({ showEnrollModal: false })}
        onSuccess={() => this.setState({ showEnrollModal: false })}
        isWizardStep={true}
        organization={this.props.organization}
      />
    );
  }

  get autoEnrollModal() {
    if (!this.state.showAutoEnrollModal) return null;
    const {
      organization: { settings: { enrollmentRule, adpCodeLists } },
      actions: { updateEnrollmentRule, addGlobalAlert, refreshSetupWizard },
      history,
    } = this.props;

    return(
      <AutoEnrollModal
        updateEnrollmentRule={updateEnrollmentRule}
        onHide={() => this.setState({ showAutoEnrollModal: false })}
        onSuccess={() => {
          this.setState({ showAutoEnrollModal: false });
          refreshSetupWizard({ refreshStep: WIZARD_STEP_NAMES.AUTO_ENROLLMENT_RULE })
            .then(() => {
              history.push(DASHBOARD_ROUTE);
              addGlobalAlert({ message: 'Account setup finished!', variant: 'success' });
            });
        }}
        enrollmentRule={enrollmentRule}
        adpCodeLists={adpCodeLists}
      >
      </AutoEnrollModal>
    );
  }

  render() {
    return(
      <div className='dashboard__content setup-wizard'>
        <div className='dashboard__wizard'>
          { this.header }
          { this.subheader }
          { this.content }
          { this.autoEnrollModal }
          { this.enrollModal }
        </div>
      </div>
    );
  }
}

export default SetupWizard;
