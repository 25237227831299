import React from 'react';
import './index.css';

/**
 * Reusable component containing a box container.
 */
function BoxContainer(props) {
  return(
    <div className='box-container'>
      { props.children }
    </div>
  );
}

export default BoxContainer;
