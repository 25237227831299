import React from 'react';
import GroupItem from './GroupItem';

class Group extends React.Component {
  get groupItems() {
    const {
      groupName,
      groupItems,
      isUserChecked,
      toggleCheckedGroupItem,
      toggleUserChecked,
      toggleCollapseSection,
    } = this.props;

    return Object.keys(groupItems).map((groupItemLabel, idx) => {
      const { users, collapsed } = groupItems[groupItemLabel];

      return (
        <GroupItem
          key={idx}
          groupName={groupName}
          groupItemLabel={groupItemLabel}
          users={users}
          collapsed={collapsed}
          isUserChecked={isUserChecked}
          toggleCheckedGroupItem={toggleCheckedGroupItem}
          toggleUserChecked={toggleUserChecked}
          toggleCollapseSection={toggleCollapseSection}
        />
      )
    })
  }

  render() {
    return(
      <>{ this.groupItems }</>
    );
  }
}

export default Group;
