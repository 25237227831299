import {
  FETCH_CURRENT_USER_PATH,
  LOGIN_USER_WITH_ADP_PATH,
  LOGOUT_USER_PATH,
} from './paths';
import { get, post } from '../ajax';
import { setSessionToken } from '../session';

export const loginUserWithAdp = async ({ authorizationCode }) => {
  return post(LOGIN_USER_WITH_ADP_PATH, { authorizationCode }, { redirectOnUnauthorized: false })
    .then(response => {
      setSessionToken(response.token);
      return response;
    });
}

export const logoutUser = async () => {
  return post(LOGOUT_USER_PATH).then(() => {
    setSessionToken('');
  });
}

export const fetchUser = async () => {
  return get(FETCH_CURRENT_USER_PATH, null, { redirectOnUnauthorized: false });
}
