import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

export default function LoadingIcon() {
  return (
    <FontAwesomeIcon
      icon={faSyncAlt}
      spin
      color='#1392BE'
      size='3x'
    />
  );
}
