import {
  ADD_GLOBAL_ALERT,
  REMOVE_GLOBAL_ALERT,
} from 'utils/actionTypes';
import { ALERT_SHOW_INTERVAL } from './constants';

export const addGlobalAlert = ({ message, variant = 'warning', autoRemove = true }) => (dispatch) => {
  dispatch({
    type: ADD_GLOBAL_ALERT,
    message,
    variant,
  });
  if (autoRemove) {
    setTimeout(() => {
      removeGlobalAlert()(dispatch);
    }, ALERT_SHOW_INTERVAL);
  }
}

export const removeGlobalAlert = (message) => (dispatch) => {
  dispatch({
    type: REMOVE_GLOBAL_ALERT,
  });
}
