import Pusher from 'pusher-js';
import { API_BASE } from './constants';
import { PUSHER_AUTH_PATH } from './api/paths';
import { getSessionToken } from './session';

export const initializePusher = () => {
  const sessionToken = getSessionToken();
  return new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    authEndpoint: `${API_BASE}${PUSHER_AUTH_PATH}`,
    auth: {
      headers: {
        authorization: `Bearer ${sessionToken}`
      }
    }
  });
};

export const EVENT_NAMES = {
  ADP_SYNC_FINISHED: 'ADP_SYNC_FINISHED',
  ENROLLMENT_FINISHED: 'ENROLLMENT_FINISHED',
  ABSORB_SYNC_FINISHED: 'ABSORB_SYNC_FINISHED',
  CUSTOM: 'CUSTOM',
}
