import {
  adpSync as adpSyncMethod,
  adpGetCodeLists as adpGetCodeListsMethod,
} from 'utils/api';
import {
  ADP_SYNC,
  GET_ADP_CODE_LISTS,
  GET_ADP_CONSENT,
} from 'utils/actionTypes';
import { SETUP_WIZARD_CALLBACK_ROUTE } from 'utils/routes';
import {
  PUBLIC_URL,
  ADP_CONSENT_MANAGER_PENDING_URL,
} from 'utils/constants';
import { addGlobalAlert, removeGlobalAlert } from './app';

export const adpSyncFulfilled = () => dispatch => {
  dispatch({ type: `${ADP_SYNC}_FULFILLED` });
}

export const adpSyncRejected = () => dispatch => {
  dispatch({ type: `${ADP_SYNC}_REJECTED` });
}

export const adpSync = (params) => (dispatch, getState) => {
  removeGlobalAlert()(dispatch);
  dispatch({ type: `${ADP_SYNC}_PENDING` });
  return adpSyncMethod(params).catch(res => {
    if (res.status === 400) {
      addGlobalAlert({ message: 'Error: another sync in progress', variant: 'danger' })(dispatch);
    } else {
      addGlobalAlert({ message: 'Unknown error. Please try again or contact support', variant: 'danger' })(dispatch);
    }
    adpSyncRejected()(dispatch);
  });
}

export const adpGetCodeLists = () => (dispatch, getState) => {
  const { app: { organization: { settings: { adpCodeLists } } } } = getState();
  if (adpCodeLists) {
    // Cache code lists on the client
    removeGlobalAlert()(dispatch);
    return Promise.resolve();
  }

  return adpGetCodeListsMethod().then(({ codeLists }) => {
    removeGlobalAlert()(dispatch);
    dispatch({
      type: `${GET_ADP_CODE_LISTS}_FULFILLED`,
      payload: codeLists,
    });
  });
}

export const getAdpConsent = () => (dispatch) => {
  dispatch({ type: `${GET_ADP_CONSENT}_PENDING` });

  const successUri = `${PUBLIC_URL}${SETUP_WIZARD_CALLBACK_ROUTE}`;
  const href = `${ADP_CONSENT_MANAGER_PENDING_URL}&successUri=${encodeURIComponent(successUri)}`
  window.location = href;
}
