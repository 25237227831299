import React from 'react';
import { NavLink } from 'react-router-dom';
import { BsHouseDoor, BsGear } from 'react-icons/bs';
import { DASHBOARD_ROUTE, SETTINGS_ROUTE, SETUP_WIZARD_ROUTE } from 'utils/routes';
import './index.css';

class Sidebar extends React.Component {
  get firstNavItem() {
    if (this.props.setupWizard) {
      return(
        <li className='dashboard__sidebar-list-item main'>
          <NavLink
            to={SETUP_WIZARD_ROUTE}
            exact={true}
            activeClassName='active'
            className='dashboard__sidebar-list-item-link'
          >
            <BsHouseDoor size='1.2em' className='dashboard__sidebar-icon' />
            Setup Wizard
          </NavLink>
        </li>
      );
    }

    return(
      <li className='dashboard__sidebar-list-item main'>
        <NavLink
          to={DASHBOARD_ROUTE}
          exact={true}
          activeClassName='active'
          className='dashboard__sidebar-list-item-link'
        >
          <BsHouseDoor size='1.2em' className='dashboard__sidebar-icon' />
          Enrollments
        </NavLink>
      </li>
    );
  }

  get settings() {
    if (this.props.setupWizard) return null;

    return(
      <li className='dashboard__sidebar-list-item settings'>
        <NavLink
          to={SETTINGS_ROUTE}
          exact={true}
          activeClassName='active'
          className='dashboard__sidebar-list-item-link'
        >
          <BsGear size='1em' className='dashboard__sidebar-icon' />
          Settings
        </NavLink>
      </li>
    );
  }

  render() {
    return(
      <div className='dashboard__sidebar'>
        <ul className='dashboard__sidebar-list'>
          { this.firstNavItem }
          <li className='dashboard__sidebar-list-item contact'>
            <img alt='Help' src='/help_icon.png' />
            <h3>Need help?</h3>
            <p>Reach out and we'll be glad to assist you.</p>
            <div className='button'>
              <a className='button__input' href='mailto:help@ryleylearning.com'>Contact us</a>
            </div>
          </li>
          { this.settings }
        </ul>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  setupWizard: false,
}

export default Sidebar;
