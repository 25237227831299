import React from 'react';
import { CONTACT_US_URL } from 'utils/constants';
import ButtonWithSpinner from 'components/ButtonWithSpinner';
import BoxContainer from 'components/BoxContainer';
import { adpGetAuthorizeUrl } from 'utils/api';
import { ADP_CONSENT_MANAGER_PENDING_URL } from 'utils/constants';

/**
 * Component containing the login box in Login UI
 */
class LoginBox extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      loading: false,
      error: '',
    }
  }

  handleAdpSsoButtonClick = () => {
    return adpGetAuthorizeUrl()
      .then((url) => {
        window.location.href = `${ADP_CONSENT_MANAGER_PENDING_URL}&successUri=${encodeURIComponent(url)}`;
      })
      .then(() => new Promise(() => {}));
  }

  get error() {
    const { error } = this.state;
    if (!error) return null;

    return(
      <div className='login__error'>
        { error }
      </div>
    );
  }

  get loginForm() {
    return(
      <div className='login__form'>
        <div className='login__adp-button-container'>
          <ButtonWithSpinner
            text='Log in with ADP'
            onClick={this.handleAdpSsoButtonClick}
          />
        </div>
      </div>
    );
  }

  render() {
    return(
      <BoxContainer>
        <div className='login__box-header-container'>
          <h1 className='login__box-header'>Login</h1>
        </div>
        <p className='login__box-paragraph'>
          Log in below to access the Ryley Learning application.
          If you have not yet signed up with Ryley Learning,&nbsp;
          <a
            href={CONTACT_US_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact Us
          </a> to get started!
        </p>
        { this.loginForm }
      </BoxContainer>
    );
  }
}

export default LoginBox;
