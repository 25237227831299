/* Global */
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER = 'FETCH_USER';
export const ADD_GLOBAL_ALERT = 'ADD_GLOBAL_ALERT';
export const REMOVE_GLOBAL_ALERT = 'REMOVE_GLOBAL_ALERT';

/* Organization */
export const GET_ORGANIZATION_ACCOUNT = 'GET_ORGANIZATION_ACCOUNT';
export const GET_ORGANIZATION_SETTINGS = 'GET_ORGANIZATION_SETTINGS';
export const GET_ORGANIZATION_ACTIVITY = 'GET_ORGANIZATION_ACTIVITY';
export const GET_ORGANIZATION_CONFIG = 'GET_ORGANIZATION_CONFIG';
export const GET_SETUP_WIZARD = 'GET_SETUP_WIZARD';
export const REFRESH_SETUP_WIZARD = 'REFRESH_SETUP_WIZARD';
export const SETUP_PUSHER = 'SETUP_PUSHER';

/* ADP */
export const GET_ADP_CODE_LISTS = 'GET_ADP_CODE_LISTS';
export const ADP_SYNC = 'ADP_SYNC';
export const GET_ADP_CONSENT = 'GET_ADP_CONSENT';

/* Enrollment and Absorb */
export const ENROLL_USERS = 'ENROLL_USERS';
export const GET_ENROLLMENTS = 'GET_ENROLLMENTS';
export const UPDATE_ENROLLMENT_RULE = 'UPDATE_ENROLLMENT_RULE';
export const ABSORB_SYNC = 'ABSORB_SYNC';
