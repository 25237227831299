import {
  ORGANIZATION_ACCOUNT_PATH,
  ORGANIZATION_SETTINGS_PATH,
  ENROLLMENT_RULE_PATH,
  ORGANIZATION_ACTIVITY_PATH,
  ORGANIZATION_CONFIG_PATH,
  SETUP_WIZARD_PATH,
  REFRESH_WIZARD_PATH,
} from './paths';
import { get, post } from '../ajax';

export const getOrganizationAccount = async () => {
  return get(ORGANIZATION_ACCOUNT_PATH);
}

export const getOrganizationSettings = async () => {
  return get(ORGANIZATION_SETTINGS_PATH);
}

export const updateEnrollmentRule = async (enrollmentRule) => {
  return post(ENROLLMENT_RULE_PATH, { enrollmentRule });
}

export const getOrganizationActivity = async () => {
  return get(ORGANIZATION_ACTIVITY_PATH);
}

export const getOrganizationConfig = async () => {
  return get(ORGANIZATION_CONFIG_PATH);
}

export const getSetupWizard = async () => {
  return get(SETUP_WIZARD_PATH);
}

export const refreshSetupWizard = async (params) => {
  return post(REFRESH_WIZARD_PATH, params);
}
